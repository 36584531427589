<template>
    <v-container class="bg-page" fluid fill-height justify-center align-center>
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <transition name="fade">
                    <v-card v-show="show" elevation="12">
                        <v-toolbar color="primary" class="white--text" flat>
                            <v-toolbar-title>{{ $t('password_reset') }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu open-on-hover offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon>
                                        <v-icon class="white--text">mdi-web</v-icon>
                                    </v-btn>
                                </template>
                                <v-list class="py-0" dense>
                                    <v-list-item @click="setLang('kk', true)">
                                        <v-list-item-title>{{ $t('kazakh') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="setLang('ru', true)">
                                        <v-list-item-title>{{ $t('russian') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="setLang('en', true)">
                                        <v-list-item-title>{{ $t('english') }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                        <v-form @submit.prevent="submit">
                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                <v-card-text class="px-6">
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <ValidationProvider ref="email" rules="required|email|min:5|max:50"
                                                                v-slot="{ errors, valid }">
                                                <v-text-field v-model="email" type="email"
                                                              :error-messages="errors" :error="!valid"
                                                              :disabled="loading" prepend-icon="mdi-email"
                                                              :label="$t('email')" clearable></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="px-4 pb-6">
                                    <v-btn text :disabled="loading" :to="{ name: 'login'}" color="grey darken-2">{{
                                        $vuetify.breakpoint.xsOnly ? $t('back') : $t('remember_your_password') }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn type="submit" color="primary" :loading="loading"
                                           :disabled="loading || invalid || !validated">{{ $t('reset_password') }}
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-form>
                    </v-card>
                </transition>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"
    import {ValidationObserver, ValidationProvider} from "vee-validate"

    export default {
        name: "Forgot",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                email: null,
                loading: false,
                show: false
            };
        },
        computed: {
            ...mapGetters(['lang']),
        },
        mounted() {
            this.show = true
        },
        methods: {
            setLang(langSelect) {
                let langLocale = localStorage.getItem('lang')
                document.documentElement.lang = langSelect ? langSelect : (langLocale ? langLocale : process.env.VUE_APP_I18N_LOCALE)
                if (typeof this.$i18n.locale !== 'undefined') {
                    this.$i18n.locale = document.documentElement.lang
                }
                this.$moment.locale(this.$i18n.locale)
                if (typeof this.$vuetify.lang.current !== 'undefined') {
                    this.$vuetify.lang.current = document.documentElement.lang
                }
            },
            async clear() {
                this.phone = null;
                requestAnimationFrame(() => {
                    this.$refs.observ.reset();
                });
            },
            async submit() {
                this.loading = true;
                await this.$http
                    .post("auth/forgot", {
                        email: this.email
                    })
                    .then(res => {
                        this.loading = false;
                        this.$toastr.success(this.$t('confirmation_code_sent_to_you_via_sms'));
                        this.$router.push({
                            name: "reset_password"
                        });
                    })
                    .catch(response => {
                        this.loading = false;
                        if (response.body.message) {
                            this.$refs.observer.setErrors([response.body.message]);
                            this.$refs.email.setErrors([
                                this.$t('you_entered_the_wrong_data')
                            ]);
                        }
                        this.$toastr.error(this.$t('you_entered_the_wrong_data'));
                    });
            },
            validateSelect() {
                if (this.email) {
                    this.$refs.observer.validate();
                }
                if (!this.email) {
                    this.$refs.email.errors = [];
                }
            }
        }
    };

</script>
